<template>
  <el-main class="rh-table">
    <h1>Tutorats</h1>
    <app-table
      tablenom="tutorats"
      :cols="colonnes"
      :items="tutorats"
      :loadstatus="tutoratsLoadStatus"
      :deletestatus="tutoratDeleteStatus"
      :formroutes="tutFormRoutes"
      :actions="tutActions"
    >
    </app-table>
  </el-main>
</template>
<script>
import AppTable from "@/components/rh/ui/table/AppTable";

export default {
  components: { "app-table": AppTable },
  data() {
    return {
      tutFormRoutes: {
        edit: { route: "tutoratedit", params: { idtutorat: "id" } },
        create: { route: "tutoratcreate" },
      },
    };
  },
  computed: {
    tutoratsLoadStatus() {
      // 1 = loading, 2 = succès, 3 = échec du chargement des données
      return this.$store.state.tut.tuteursLoadStatus;
    },
    tutoratDeleteStatus() {
      // 1 = deleting, 2 = succès, 3 = échec
      return this.$store.state.tut.tuteurDeleteStatus;
    },
    tutorats() {
      return this.$store.state.tut.tuteurs;
    },
    colonnes() {
      return this.$store.state.tut.colonnes;
    },
    tutActions() {
      return this.$store.state.tut.actions;
    },
  },
};
</script>
